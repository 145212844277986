import { default as indexrtDe3gPkByMeta } from "/builds/qmex/q-gateway/frontend/app/pages/index.vue?macro=true";
import { default as languageMEjAcaD2m0Meta } from "/builds/qmex/q-gateway/frontend/app/pages/language.vue?macro=true";
import { default as fin_apirVA3voVIP4Meta } from "/builds/qmex/q-gateway/frontend/app/pages/organizations/[id]/add-service/fin_api.vue?macro=true";
import { default as libraesvanHEvTGNjmyMeta } from "/builds/qmex/q-gateway/frontend/app/pages/organizations/[id]/add-service/libraesva.vue?macro=true";
import { default as otsfnY9zcyiCiMeta } from "/builds/qmex/q-gateway/frontend/app/pages/organizations/[id]/add-service/ots.vue?macro=true";
import { default as smsJLKH0RvxZTMeta } from "/builds/qmex/q-gateway/frontend/app/pages/organizations/[id]/add-service/sms.vue?macro=true";
import { default as telecomRe609HLcv0Meta } from "/builds/qmex/q-gateway/frontend/app/pages/organizations/[id]/add-service/telecom.vue?macro=true";
import { default as _91hostname_93SLSnSPc8zsMeta } from "/builds/qmex/q-gateway/frontend/app/pages/organizations/[id]/fin-api/[hostname].vue?macro=true";
import { default as indexAd3VB70co5Meta } from "/builds/qmex/q-gateway/frontend/app/pages/organizations/[id]/index.vue?macro=true";
import { default as _91hostname_93pIMN4HLpVxMeta } from "/builds/qmex/q-gateway/frontend/app/pages/organizations/[id]/libraesva-api/[hostname].vue?macro=true";
import { default as _91member_id_93WUJOfwREIXMeta } from "/builds/qmex/q-gateway/frontend/app/pages/organizations/[id]/members/[member_id].vue?macro=true";
import { default as _91hostname_93h0GzSJltqJMeta } from "/builds/qmex/q-gateway/frontend/app/pages/organizations/[id]/q-ots/[hostname].vue?macro=true";
import { default as _91hostname_93NRHQ1bEiL6Meta } from "/builds/qmex/q-gateway/frontend/app/pages/organizations/[id]/q-sms/[hostname].vue?macro=true";
import { default as _91hostname_93cJFJJlXIT6Meta } from "/builds/qmex/q-gateway/frontend/app/pages/organizations/[id]/q-telecom-public-api/[hostname].vue?macro=true";
import { default as createoKMG0W43G5Meta } from "/builds/qmex/q-gateway/frontend/app/pages/organizations/create.vue?macro=true";
import { default as indexdAplDfqu2UMeta } from "/builds/qmex/q-gateway/frontend/app/pages/organizations/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/builds/qmex/q-gateway/frontend/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "language",
    path: "/language",
    component: () => import("/builds/qmex/q-gateway/frontend/app/pages/language.vue").then(m => m.default || m)
  },
  {
    name: "organizations-id-add-service-fin_api",
    path: "/organizations/:id()/add-service/fin_api",
    component: () => import("/builds/qmex/q-gateway/frontend/app/pages/organizations/[id]/add-service/fin_api.vue").then(m => m.default || m)
  },
  {
    name: "organizations-id-add-service-libraesva",
    path: "/organizations/:id()/add-service/libraesva",
    component: () => import("/builds/qmex/q-gateway/frontend/app/pages/organizations/[id]/add-service/libraesva.vue").then(m => m.default || m)
  },
  {
    name: "organizations-id-add-service-ots",
    path: "/organizations/:id()/add-service/ots",
    component: () => import("/builds/qmex/q-gateway/frontend/app/pages/organizations/[id]/add-service/ots.vue").then(m => m.default || m)
  },
  {
    name: "organizations-id-add-service-sms",
    path: "/organizations/:id()/add-service/sms",
    component: () => import("/builds/qmex/q-gateway/frontend/app/pages/organizations/[id]/add-service/sms.vue").then(m => m.default || m)
  },
  {
    name: "organizations-id-add-service-telecom",
    path: "/organizations/:id()/add-service/telecom",
    component: () => import("/builds/qmex/q-gateway/frontend/app/pages/organizations/[id]/add-service/telecom.vue").then(m => m.default || m)
  },
  {
    name: "organizations-id-fin-api-hostname",
    path: "/organizations/:id()/fin-api/:hostname()",
    component: () => import("/builds/qmex/q-gateway/frontend/app/pages/organizations/[id]/fin-api/[hostname].vue").then(m => m.default || m)
  },
  {
    name: "organizations-id",
    path: "/organizations/:id()",
    component: () => import("/builds/qmex/q-gateway/frontend/app/pages/organizations/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "organizations-id-libraesva-api-hostname",
    path: "/organizations/:id()/libraesva-api/:hostname()",
    component: () => import("/builds/qmex/q-gateway/frontend/app/pages/organizations/[id]/libraesva-api/[hostname].vue").then(m => m.default || m)
  },
  {
    name: "organizations-id-members-member_id",
    path: "/organizations/:id()/members/:member_id()",
    component: () => import("/builds/qmex/q-gateway/frontend/app/pages/organizations/[id]/members/[member_id].vue").then(m => m.default || m)
  },
  {
    name: "organizations-id-q-ots-hostname",
    path: "/organizations/:id()/q-ots/:hostname()",
    component: () => import("/builds/qmex/q-gateway/frontend/app/pages/organizations/[id]/q-ots/[hostname].vue").then(m => m.default || m)
  },
  {
    name: "organizations-id-q-sms-hostname",
    path: "/organizations/:id()/q-sms/:hostname()",
    component: () => import("/builds/qmex/q-gateway/frontend/app/pages/organizations/[id]/q-sms/[hostname].vue").then(m => m.default || m)
  },
  {
    name: "organizations-id-q-telecom-public-api-hostname",
    path: "/organizations/:id()/q-telecom-public-api/:hostname()",
    component: () => import("/builds/qmex/q-gateway/frontend/app/pages/organizations/[id]/q-telecom-public-api/[hostname].vue").then(m => m.default || m)
  },
  {
    name: "organizations-create",
    path: "/organizations/create",
    component: () => import("/builds/qmex/q-gateway/frontend/app/pages/organizations/create.vue").then(m => m.default || m)
  },
  {
    name: "organizations",
    path: "/organizations",
    component: () => import("/builds/qmex/q-gateway/frontend/app/pages/organizations/index.vue").then(m => m.default || m)
  }
]