import { library, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faArrowsRotate,
  faBars,
  faCheck,
  faCog,
  faExclamation,
  faHouse,
  faLanguage,
  faQuestion,
  faRefresh,
  faRightFromBracket,
  faRightToBracket,
  faSms,
  faSpinner,
  faStar,
  faUser,
  faXmark,
  faBuilding,
  faTrash,
  faPalette,
  faUserSecret
} from '@fortawesome/free-solid-svg-icons'
import { defineNuxtPlugin } from '#app'

// This is important, we are going to let Nuxt.js worry about the CSS
config.autoAddCss = false

// You can add your icons directly in this plugin. See other examples for how you
// can add other styles or just individual icons.
library.add(faSpinner,
  faRightToBracket,
  faBars,
  faBuilding,
  faRightFromBracket,
  faSms,
  faUserSecret,
  faHouse,
  faLanguage,
  faCheck,
  faXmark,
  faArrowsRotate,
  faQuestion,
  faUser,
  faRefresh,
  faStar,
  faTrash,
  faExclamation,
  faPalette,
  faCog,
)

// Register the component globally
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('fa-icon', FontAwesomeIcon)
})
