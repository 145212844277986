import { useState } from '#app'
import type { Ref} from 'vue';
import {ref, watch} from 'vue'

export class UserInfo {
  userId: string
  name: string
  email: string

  org: string
  reseller: boolean
  userType: string

  constructor(userId: string, name: string, email: string, org: string, userType: string) {
    this.userId = userId;
    this.name = name;
    this.email = email;
    this.org = org;
    this.userType = userType;
    this.reseller = false
  }
}

// List of all states
export const useCurrentUser = () => usePersistedState<UserInfo>('user',
  () => new UserInfo('', '', '', '', 'anonymous'))

export const useTemporaryPass = ref("")
export const useTemporaryUser = ref("")

// usePersistedState attempts to load previous values from localStorage and sets up a watcher to persist
// any updates to localStorage for the given key.
function usePersistedState<T> (key: string, init?: () => T | Ref<T>): Ref<T> {
  // Do not register anything if it's already registered
  if (key in refs) {
    return refs[key]
  }

  // TODO: can there be a data race?

  // Check is there is an old value to load
  const oldValue: string|null = import.meta.client
    ? window.localStorage?.getItem(`state.${key}`)
    : null

  const parseOrInit = () => {
    if (!oldValue) {
      if (init !== undefined) {
        return init()
      } else {
        return undefined
      }
    } else {
      return JSON.parse(oldValue)
    }
  }

  const ref = useState<T>(key, parseOrInit)
  refs[key] = ref

  // Register watcher to persist changes
  if (import.meta.client) {
    // @ts-ignore - no idea why this works but not watching `ref`, but ok...
    watch(ref.value, (newValue) => {
      window.localStorage.setItem(`state.${key}`, JSON.stringify(newValue))
    })
  }

  return ref
}

// In-memory list of refs, such that we register our handlers only once
const refs: {
  [key: string]: Ref
} = {}

