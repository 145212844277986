<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup>
import {useCurrentUser} from "~/composables/states";

// const route = useRoute()
useHead({
  // meta: [{ titleTemplate: titleChunk => {
  //     return titleChunk ? `${titleChunk}` : '';
  //   }}]
  titleTemplate: '%s - Q-MEX Management',
})

const user = useCurrentUser()
window.fetch('/api/me')
  .then(async res => {
    if (!res.ok) {
      // We are not authenticated, so let's return to the login page
      const resp = await res.json()
      console.error(resp.error)
      window.location.href = '/auth/?returnUrl=' + encodeURIComponent(window.location.pathname + window.location.search)
      throw new Error("login required: " + resp.error)
    }

    return res.json()
  }).then(loggedInUser => {
    user.value.userId = loggedInUser.userId
    user.value.email = loggedInUser.email
    user.value.name = loggedInUser.name
    user.value.userType = loggedInUser.type
    user.value.org = loggedInUser.org
    console.debug("logged in as", user)
  })
  .then(() => window.fetch(`/api/organizations/${user.value.org}`))
  .then(async res => {
    if (!res.ok) {
      const err = await res.json()
      throw new Error(err.error)
    }

    return res.json()
  })
  .then(apiOrg => {
    user.value.reseller = apiOrg.reseller
  })

  .catch(() => {}) // ignore the error, since it happens every time we are not logged in

document.title = 'Q-MEX Management'

</script>
